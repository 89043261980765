import { useAuthContext } from "./useAuthContext";
import config from "../config";

export const useLogout = () => {
  const { dispatch } = useAuthContext();

  const logout = async () => {
    // Call the /auth/logout endpoint
    await fetch(`${config.apiUrl}/auth/logout`, {
      method: "POST",
      credentials: "include",
    });

    // Remove user from storage
    localStorage.removeItem("user");

    // Dispatch logout action
    dispatch({ type: "LOGOUT" });
  };

  return { logout };
};
