import { useState, useEffect } from "react";
import {
  Button,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  ComboBox,
} from "@carbon/react";
import { Download } from "@carbon/icons-react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { DatePickerForm } from "../../components/DatePickerForm/DatePickerForm";
import { fetchGuides } from "../ToursPage/ManageGuidesModalUtils";
import { useShifts, useDetailedShifts } from "./ShiftHelpers";

const ShiftsPage = () => {
  const { user } = useAuthContext();

  const [guides, setGuides] = useState([]);
  useEffect(() => {
    fetchGuides(user, setGuides);
  }, [user]);
  const [guide, setGuide] = useState("");

  useEffect(() => {
    if (user.role !== "Manager") {
      setGuide(user);
    }
  }, [user]);
  const [timeRange, setTimeRange] = useState({
    start: new Date(),
    end: new Date(),
  });

  console.log("user company");

  const [selectedDateRange, setSelectedDateRange] = useState([]);
  console.log("selectedDateRange", selectedDateRange);
  // const { user } = useAuthContext();

  const setDateRange = (dates) => {
    if (
      dates &&
      dates.length > 1 &&
      JSON.stringify(dates) !== JSON.stringify([timeRange.start, timeRange.end])
    ) {
      let endDate = new Date(dates[1]);
      endDate.setHours(23, 59, 59, 999); // Set the time to 23:59:59.999

      setTimeRange({
        start: new Date(dates[0]),
        end: endDate,
      });
    }
  };

  const detailedShiftsData = useDetailedShifts(user, guide, timeRange);
  const shiftsData = useShifts(user, guide, timeRange);
  console.log("detailedShiftsData", detailedShiftsData);
  console.log("shiftsData", shiftsData);
  const { shifts, rows, headers } = user.company.detailedShifts
    ? detailedShiftsData
    : shiftsData;
  console.log("rows before being rendered Final", rows);

  function handleDownload() {
    const csvData = convertToCSV(headers, rows);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    const startDate = timeRange.start.toLocaleDateString("en-GB");
    const endDate = timeRange.end.toLocaleDateString("en-GB");
    link.setAttribute(
      "download",
      `viamki_${guide.name}-${startDate}to${endDate}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function convertToCSV(headers, rows) {
    const csvRows = [];

    // get the headers
    const headerLine = headers.map((header) => header.header).join(",");
    csvRows.push(headerLine);

    // loop over the rows
    for (const row of rows) {
      const values = headers.map((header) => {
        const cellValue = row[header.key];
        const escaped =
          cellValue !== undefined ? ("" + cellValue).replace(/"/g, '\\"') : "";
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    // form the csv data
    const csvData = csvRows.join("\n");
    return csvData;
  }

  return (
    <>
      <div>
        <DatePickerForm
          selectedDateRange={selectedDateRange}
          setDates={setDateRange}
        />
      </div>{" "}
      {user.role === "Manager" ? (
        <ComboBox
          className="guide-shift-dropdown"
          id="guide"
          titleText="Guide"
          placeholder="Select or Type the guide name"
          items={guides}
          itemToString={(item) => (item ? item.name : "")}
          selectedItem={guide}
          onChange={({ selectedItem }) => setGuide(selectedItem)}
        />
      ) : (
        <div className="guide-shift-dropdown"></div>
      )}
      <Button
        onClick={handleDownload}
        renderIcon={Download}
        iconDescription="Download"
      >
        Download
      </Button>
      <DataTable
        rows={rows}
        headers={headers}
        render={({ rows, headers, getHeaderProps }) => (
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{cell.value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
    </>
  );
};

export default ShiftsPage;
