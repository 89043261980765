import React, { useState } from "react";
import {
  Button,
  PasswordInput,
  Stack,
  Form,
  InlineNotification,
} from "@carbon/react";
import config from "../../config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { customFetch } from "../../helpers/customFetch";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState("initial");

  const { user } = useAuthContext();
  const email = user.email;
  const handleChangePassword = async (e) => {
    e.preventDefault();

    try {
      const response = await customFetch(
        `${config.apiUrl}/api/user/changePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ email, oldPassword, newPassword }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        setError(data.error);
        setSubmissionStatus("error");
      } else {
        setSubmissionStatus("success");
      }
      // console.log("Response data:", data);
    } catch (error) {
      // console.error("Error during fetch:", error);
    }
  };

  return (
    <Form className="landing-page" onSubmit={handleChangePassword}>
      <Stack gap={8}>
        <h2>Change Password</h2>
        <PasswordInput
          id="oldPassword"
          labelText=""
          type="password"
          onChange={(e) => setOldPassword(e.target.value)}
          value={oldPassword}
          placeholder="Current Password"
          className="fixed-width-input"
        />
        <PasswordInput
          id="newPassword"
          labelText=""
          type="password"
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword}
          placeholder="New Password"
          className="fixed-width-input"
        />

        {submissionStatus === "error" && (
          <InlineNotification
            // className="notification-margin"
            className="fixed-width-input"
            kind="error"
            title={error}
          />
        )}
        {submissionStatus === "success" && (
          <InlineNotification
            kind="success"
            title="Password changed successfully"
          />
        )}
        <Button type="submit">Change Password</Button>
      </Stack>
    </Form>
  );
};

export default ChangePassword;
