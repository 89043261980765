// customFetch.js
import { fetchWithTokenRefresh } from "./refreshToken";

export const customFetch = async (url, options = {}, accessToken) => {
  // Add the access token to the request headers
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`,
  };

  // Use fetchWithTokenRefresh to handle token refresh logic
  const response = await fetchWithTokenRefresh(url, options);

  // If the response status is 401, redirect to the login page
  if (response.status === 401) {
    window.location.href = "/login";
    return;
  }

  return response;
};
