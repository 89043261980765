import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from "@carbon/react";

import ProfileMenu from "../ProfileMenu/ProfileMenu";
import { Notification } from "@carbon/react/icons";
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

const MainHeader = () => {
  const { user } = useAuthContext();
  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="Viamki header menu">
          <SkipToContent />
          <HeaderMenuButton
            aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName as={NavLink} to="/" prefix="Viamki">
            {user && user.company ? user.company.name : ""}
          </HeaderName>
          <HeaderNavigation aria-label="Header Menu">
            <HeaderMenuItem as={NavLink} to={`/`}>
              Bookings
            </HeaderMenuItem>
            <HeaderMenuItem as={NavLink} to="/reports">
              No Shows
            </HeaderMenuItem>
            <HeaderMenuItem as={NavLink} to="/fees">
              Fees
            </HeaderMenuItem>
            <HeaderMenuItem as={NavLink} to="/guides">
              Team
            </HeaderMenuItem>
            <HeaderMenuItem as={NavLink} to="/shifts">
              Shifts
            </HeaderMenuItem>
            <HeaderMenuItem as={NavLink} to="/account">
              Account
            </HeaderMenuItem>
          </HeaderNavigation>
          <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isPersistent={false}
            onOverlayClick={onClickSideNavExpand}
            // onSideNavBlur={onClickSideNavExpand}
          >
            <SideNavItems>
              <HeaderSideNavItems>
                <HeaderMenuItem as={NavLink} to={`/`}>
                  Bookings
                </HeaderMenuItem>
              </HeaderSideNavItems>
              <HeaderSideNavItems>
                <HeaderMenuItem as={NavLink} to="/reports">
                  No Shows
                </HeaderMenuItem>
              </HeaderSideNavItems>
              <HeaderMenuItem as={NavLink} to="/fees">
                Fees
              </HeaderMenuItem>
              <HeaderSideNavItems></HeaderSideNavItems>
              <HeaderSideNavItems>
                <HeaderMenuItem as={NavLink} to="/guides">
                  Team
                </HeaderMenuItem>
              </HeaderSideNavItems>
              <HeaderSideNavItems>
                <HeaderMenuItem as={NavLink} to="/shifts">
                  Shifts
                </HeaderMenuItem>
              </HeaderSideNavItems>
              <HeaderSideNavItems>
                <HeaderMenuItem as={NavLink} to="/account">
                  Manage account
                </HeaderMenuItem>
              </HeaderSideNavItems>
              <HeaderSideNavItems>
                {/* <HeaderMenuItem as={NavLink} to="/billing">
                  Billing
                </HeaderMenuItem> */}
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>
          <HeaderGlobalBar>
            <HeaderGlobalAction
              aria-label="Notifications"
              tooltipAlignment="center"
            >
              <Notification size={20} />
            </HeaderGlobalAction>
            <ProfileMenu />
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};

export default MainHeader;
