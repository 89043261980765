const RadioButtonsForm = ({ selectedRadio, onRadioChange }) => {
  return (
    <div className="radio-form-group">
      <p className="form-info">Type of report</p>
      <div className="radio">
        <label className="radio-elements">
          <input
            className="radio-input"
            type="radio"
            value="checkinStatus"
            checked={selectedRadio === "checkinStatus"}
            onChange={onRadioChange}
          />
          <p className="radio-text">Check-in Status</p>
        </label>
      </div>
      <div className="radio">
        <label className="radio-elements">
          <input
            className="radio-input"
            type="radio"
            value="reservationDate"
            checked={selectedRadio === "reservationDate"}
            onChange={onRadioChange}
          />
          <p className="radio-text">Reservation Date</p>
        </label>
      </div>
    </div>
  );
};

export { RadioButtonsForm };
