import { useState, useEffect } from "react";
import { Tile, Select, SelectItem, Row } from "@carbon/react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { customFetch } from "../../helpers/customFetch";
import config from "../../config";

function TourCustomiser() {
  const { user } = useAuthContext();
  const [colors, setColors] = useState({});
  const [flags, setFlags] = useState({});

  const colorOptions = [
    { text: "Blue", value: "blue" },
    { text: "Pink", value: "pink" },
    { text: "Green", value: "green" },
    { text: "Brown", value: "brown" },
    { text: "Black", value: "black" },
    { text: "Yellow", value: "yellow" },
  ];
  const flagOptions = [
    { text: "None", value: "none" },
    { text: "Spanish", value: "spain" },
    { text: "English", value: "uk" },
    { text: "Italian", value: "italy" },
    { text: "French", value: "france" },
  ];

  const fetchColors = async () => {
    const newColors = {};
    setColors(newColors);
  };

  useEffect(() => {
    fetchColors();
  }, []);

  const fetchFlags = async () => {
    const newFlags = {};
    setFlags(newFlags);
  };

  useEffect(() => {
    fetchFlags();
  }, []);
  const tours = user.company.tours;
  const companyId = user.company._id;
  const updateColor = async (tourId, newColor) => {
    // Update the color in the backend
    const tour = tours.find((tour) => tour.id === tourId);
    if (!tour) {
      throw new Error("Tour not found");
    }
    try {
      console.log("we are here");

      const response = await customFetch(
        `${config.apiUrl}/api/company/${companyId}/${tourId}/color`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ color: newColor }),
        },
        user.accessToken // pass the access token here
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Color updated successfully:", data);
      setColors((prevColors) => ({ ...prevColors, [tourId]: newColor }));
    } catch (error) {
      console.error("Failed to update color:", error);
    }
  };

  const updateFlag = async (tourId, newFlag) => {
    // Update the flag in the backend
    const tour = tours.find((tour) => tour.id === tourId);
    if (!tour) {
      throw new Error("Tour not found");
    }
    try {
      const response = await customFetch(
        `${config.apiUrl}/api/company/${companyId}/${tourId}/flag`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ flag: newFlag }),
        },
        user.accessToken // pass the access token here
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // Update the flag in the state
      setFlags({ ...flags, [tourId]: newFlag });
    } catch (error) {
      console.error("Error updating flag:", error);
    }
  };

  return (
    <>
      <section className="tile-group-container">
        <h2>Customise tours</h2>
        <p>*Note: Logout and login again to see changes reflected</p>
        {tours.map((tour) => (
          <Tile className="tile-tour-customiser" key={tour.id}>
            <p>{tour.label}</p>
            <Row className="tour-selector-group">
              <Select
                id={`select-flag-${tour.id}`}
                inline={true}
                labelText="Language icon"
                value={flags[tour.id] || tour.flag} // Set the value to the selected flag for the current tour
                onChange={(e) => updateFlag(tour.id, e.target.value)}
              >
                {flagOptions.map((option) => (
                  <SelectItem
                    key={option.value}
                    text={option.text}
                    value={option.value}
                  />
                ))}
              </Select>
              <div className="color-selector">
                <div className={`square color-${tour.color}`} />
                <Select
                  id={`select-color-${tour.id}`}
                  labelText=""
                  inline={true}
                  value={colors[tour.id] || tour.color} // Set the value to the selected color for the current tour
                  onChange={(e) => updateColor(tour.id, e.target.value)}
                >
                  {colorOptions.map((colorOption) => (
                    <SelectItem
                      key={colorOption.value}
                      text={colorOption.text}
                      value={colorOption.value}
                    />
                  ))}
                </Select>
              </div>
            </Row>
          </Tile>
        ))}
      </section>
    </>
  );
}

export default TourCustomiser;
