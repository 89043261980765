import config from "../../config";
import { customFetch } from "../../helpers/customFetch";

export const fetchGuides = async (user, setGuides) => {
  const response = await customFetch(
    `${config.apiUrl}/api/user/guides/company`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    }
  );

  if (response.ok) {
    const guides = await response.json();
    setGuides(guides);
  }
};

export const fetchShift = async (
  time,
  date,
  tour,
  company,
  shift,
  setShift
) => {
  const response = await fetch(
    `${config.apiUrl}/api/shift/${time}/${date}/${tour}/${company}`
  );
  if (response.ok) {
    const fetchedShift = await response.json();
    if (JSON.stringify(fetchedShift) !== JSON.stringify(shift)) {
      setShift(fetchedShift); // Update the shift state
    }
  } else if (response.status === 404) {
    console.log("Shift not found");
  }
};

export const updateShift = async (
  shift,
  assignedAttendees,
  guides,
  selectedGuides,
  user,
  setShift
) => {
  if (!shift) return;
  console.log("SHIFT", shift);
  const selectedGuidesObjects = Object.entries(assignedAttendees).map(
    ([guideId, attendees]) => {
      const guide = guides.find((guide) => guide._id === guideId);
      const attendeesArray = Object.entries(attendees).map(([key, value]) => ({
        [key]: value,
      }));
      return {
        ...guide,
        attendees: attendeesArray,
      };
    }
  );

  const response = await fetch(
    `${config.apiUrl}/api/shift/${encodeURIComponent(
      shift.shiftString
    )}/guides`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        selectedGuides: { selectedItems: selectedGuidesObjects },
      }),
    }
  );
  const data = await response.json();
  if (response.ok) {
    setShift(data); // Update the shift state with the new data
    console.log("Guides added to shift successfully");
    console.log("updated selectedGuides", selectedGuides);
    console.log("shift guides", shift && shift.guides);
  } else {
    console.log("Failed to add guides to shift");
  }
};
