import {
  DataTable,
  Table,
  Button,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import { Download } from "@carbon/icons-react";

const ReportDataTable = ({ reportTable, headers, dataSubmitted }) => {
  let newHeaders = headers
    .map((header) => {
      if (header.key === "noShow") {
        return [
          { key: "adults", header: "Adults" },
          { key: "children", header: "Children" },
        ];
      } else {
        return header;
      }
    })
    .flat();

  function handleDownload() {
    const csvData = convertTableToCSV(reportTable, newHeaders);
    triggerCSVDownload(csvData);
  }

  function convertTableToCSV(rows, headers) {
    let csv = headers.map((header) => header.header).join(",") + "\n";

    rows.forEach((row) => {
      let adultChild = row["noShow"].split(", ");
      row["adults"] = adultChild[0].split(": ")[1];
      row["children"] =
        adultChild.length > 1 ? adultChild[1].split(": ")[1] : "";
      let rowValues = newHeaders.map((header) => {
        let value = row[header.key];
        if (Array.isArray(value)) {
          return value.join(" ");
        } else if (typeof value === "object" && value !== null) {
          return Object.values(value).join(" ");
        } else {
          return value;
        }
      });

      csv += rowValues.join(",") + "\n";
    });

    return csv;
  }

  function triggerCSVDownload(csvData) {
    // Create a Blob with the CSV data
    let blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    let downloadLink = document.createElement("a");

    // Set the download link href to the object URL of the Blob
    downloadLink.href = URL.createObjectURL(blob);

    // Set the download attribute of the link to the desired file name
    downloadLink.download = "data.csv";

    // Append the link to the body
    document.body.appendChild(downloadLink);

    // Simulate a click on the link
    downloadLink.click();

    // Remove the link from the body
    document.body.removeChild(downloadLink);
  }

  return (
    <>
      {reportTable.length !== 0 && (
        <Button
          onClick={handleDownload}
          renderIcon={Download}
          iconDescription="Download"
        >
          Download
        </Button>
      )}

      <DataTable rows={reportTable} headers={headers} isSortable>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSubmitted && reportTable.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={headers.length}>
                    {`No data to display. You may try different filters :)`}
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row) => (
                  <TableRow key={row.id}>
                    {console.log(row.id)}
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
      </DataTable>
    </>
  );
};

export { ReportDataTable };
