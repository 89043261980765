import React from "react";
import { Column } from "@carbon/react";
import { Calendar } from "@carbon/react/icons";

const CalendarBarDate = ({ date }) => (
  <Column className="calendar-bar-date">
    <div className="calendar-bar-date-and-icon">
      <div className="calendar-bar-calendar-icon">
        <Calendar size={24} />
      </div>{" "}
      {date}
    </div>
  </Column>
);

const CalendarBarChevron = ({ icon }) => {
  return <Column>{icon()}</Column>;
};

export { CalendarBarDate, CalendarBarChevron };
