import { useState, useEffect } from "react";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Button,
  Dropdown,
} from "@carbon/react";
import config from "../../config";
import { customFetch } from "../../helpers/customFetch";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getSortedTours } from "../../helpers/bookingsHelper";
import { DatePickerFormSingle } from "../../components/DatePickerForm/DatePickerForm";
import { Download } from "@carbon/icons-react";

const ReportsPage = () => {
  const today = new Date().toLocaleDateString("sv");
  const [bookings, setBookings] = useState(null);
  const [day, setDay] = useState(today);
  const [ageGroup, setAgeGroup] = useState("Adults");

  const { user } = useAuthContext();
  console.log(bookings);
  useEffect(() => {
    const fetchBookings = async () => {
      const response = await customFetch(
        `${config.apiUrl}/api/bookings/fees`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ day: day }),
        },
        user.token
      );

      const json = await response.json();
      if (response.ok) {
        setBookings(json);
      }
    };
    if (user) {
      fetchBookings();
    }
  }, [day, user]);

  const dailytours = bookings?.length > 0 ? getSortedTours(bookings) : [];
  const calculateAttendees = (attendees, ageGroup) => {
    if (!attendees || attendees.length === 0) {
      return 0;
    }
    const sum = attendees.reduce((sum, attendee) => {
      if (attendee[ageGroup] !== undefined) {
        sum += attendee[ageGroup];
      }
      return sum;
    }, 0);
    return sum === 0 ? "" : sum;
  };
  const sourceData = dailytours.reduce((acc, current) => {
    const existingItem = acc.find(
      (item) => item.tourTime === `${current.time} ${current.tour}`
    );
    const sumOfAttendees = Number(
      calculateAttendees(current.attendees, ageGroup)
    );
    if (existingItem) {
      if (existingItem[current.source]) {
        existingItem[current.source] += sumOfAttendees;
      } else {
        existingItem[current.source] = sumOfAttendees;
      }
    } else {
      acc.push({
        tourTime: `${current.time} ${current.tour}`,
        [current.source]: sumOfAttendees,
      });
    }
    return acc;
  }, []);

  const allSources = Array.from(
    new Set(user.company.sources.map((item) => item.label))
  );

  const headers = [
    {
      key: "date",
      header: "Date",
    },
    {
      key: "tourTime",
      header: "Tour",
    },
    ...allSources.map((source) => ({
      key: source,
      header: source,
    })),
  ];

  const rows = sourceData.map((item) => ({
    id: item.tourTime, // Set id to the unique combination of tour and time
    date: day, // Add the 'day' value to each row
    ...item,
  }));

  function handleDownload() {
    const csvData = convertToCSV(headers, rows);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `viamki-fees-${ageGroup}-${day}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function convertToCSV(headers, rows) {
    const csvRows = [];

    // get the headers
    const headerLine = headers.map((header) => header.header).join(",");
    csvRows.push(headerLine);

    // loop over the rows
    for (const row of rows) {
      const values = headers.map((header) => {
        const cellValue = row[header.key];
        const escaped =
          cellValue !== undefined ? ("" + cellValue).replace(/"/g, '\\"') : "";
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    // form the csv data
    const csvData = csvRows.join("\n");
    return csvData;
  }

  return (
    <>
      <DatePickerFormSingle setDay={setDay} />
      <Dropdown
        className="age-group-dropdown"
        id="ageGroup"
        titleText="Customer Type"
        items={["Adults", "Teens", "Children"]}
        selectedItem={ageGroup}
        onChange={({ selectedItem }) => setAgeGroup(selectedItem)}
      />
      <div className="fee-table">
        <Button
          onClick={handleDownload}
          renderIcon={Download}
          iconDescription="Download"
        >
          Download
        </Button>
        <DataTable
          rows={rows}
          headers={headers}
          render={({ rows, headers, getHeaderProps }) => (
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </div>
    </>
  );
};

export default ReportsPage;
