// useRefreshBookings.js
import { useCallback } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { customFetch } from "../helpers/customFetch";
import config from "../config";
export const useRefreshBookings = (day, time, tour, setDailyBookings) => {
  const { user } = useAuthContext();

  return useCallback(async () => {
    try {
      const response = await customFetch(
        `${config.apiUrl}/api/bookings/date/${day}/${time}/${tour}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.ok) {
        setDailyBookings(json);
        return json; // return the updated bookings
      } else {
        console.error("Error:", json);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [day, time, tour, user, setDailyBookings]);
};
