import React, { useEffect, useState } from "react";
import { Grid, Column, Button } from "@carbon/react";
import {
  CalendarBarChevron,
  CalendarBarDate,
} from "../../components/CalendarBar/CalendarBar";
import getPrevOrNextDay from "../../helpers/getPrevOrNextDay";
import { useAuthContext } from "../../hooks/useAuthContext";
import { ChevronLeft, ChevronRight } from "@carbon/react/icons";
import { useParams, Link } from "react-router-dom";
import { getDailyTours } from "../../helpers/bookingsHelper";
import config from "../../config";
import { customFetch } from "../../helpers/customFetch";

const BookingsPage = () => {
  const [bookings, setBookings] = useState(null);
  const { day } = useParams();
  const { user } = useAuthContext();
  // console.log(bookings);
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await customFetch(
          `${config.apiUrl}/api/bookings/date/${day}`,
          {
            method: "GET",
          },
          user.token
        );
        const json = await response.json();
        if (response.ok) {
          setBookings(json);
        } else {
          throw new Error(json.message || "Something went wrong");
        }
      } catch (error) {
        console.error(error);
        // Here you can handle the error, for example, set an error state
        // setError(error.message);
      }
    };
    if (user) {
      fetchBookings();
    }
  }, [day, user]);

  const timezone = user.company.timezone;
  const prevDay = getPrevOrNextDay(day, "prevDay", timezone);
  const nextDay = getPrevOrNextDay(day, "nextDay", timezone);
  const localOffset = new Date().getTimezoneOffset();

  // Create the date based on the local time offset
  const date =
    localOffset <= 0
      ? new Date(day + "T00:00:00Z")
      : new Date(day + "T00:00:00");

  const calendarBarDate = new Intl.DateTimeFormat("en-UK", {
    timeZone: timezone,
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(date);
  const tours = user.company.tours;

  let dailyTours = null;

  if (bookings && bookings.length === 0) {
    dailyTours = (
      <p className="no-bookings-message">No bookings recorded this day</p>
    );
  } else if (bookings) {
    dailyTours = getDailyTours(bookings, tours, user);
  }

  return (
    <>
      <h2 className="h2-bottom-margin">Daily bookings</h2>
      <Column lg={16} md={8} sm={4} className="bookings-page__r3">
        <Grid className="bookings-page-calendar-bar">
          <Link
            to={`/bookings/date/${prevDay}`}
            className="bookings-page__calendar-chevron gray-color"
          >
            <CalendarBarChevron icon={() => <ChevronLeft size={24} />} />
          </Link>
          <Button className="bookings-calendar-bar-center gray-color">
            <CalendarBarDate date={calendarBarDate} />
          </Button>

          <Link
            to={`/bookings/date/${nextDay}`}
            className="bookings-page__calendar-chevron gray-color"
          >
            <CalendarBarChevron icon={() => <ChevronRight size={24} />} />
          </Link>
        </Grid>
        <section> {dailyTours}</section>{" "}
      </Column>
    </>
  );
};

export default BookingsPage;
