// config.js
const config = {
  apiUrl:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL
      : "http://localhost:4000",
};

console.log("API_URL:", process.env.REACT_APP_API_URL);

export default config;
