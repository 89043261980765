const formatNoShowString = (noShow) => {
  return noShow
    .map((obj) => {
      const key = Object.keys(obj)[0];
      const type = key.charAt(0).toUpperCase() + key.slice(1);
      const value = obj[key];
      return value === 0 ? null : `${type}: ${value}`;
    })
    .filter((str) => str !== null)
    .join(", ");
};

const getNoShowTable = (reportTable) => {
  return reportTable
    .map((item) => {
      if (!item.attendees || item.attendees.length === 0) {
        const noShowString = formatNoShowString(item.customers);
        return { ...item, noShow: noShowString };
      }

      const noShow = item.customers.reduce((acc, curr, index) => {
        const customerType = Object.keys(curr)[0];
        const showedType = Object.keys(item.attendees[index] || {})[0];
        if (customerType === showedType) {
          const noShowType = {
            [customerType]:
              curr[customerType] - item.attendees[index][showedType],
          };
          return [...acc, noShowType];
        }
        return acc;
      }, []);

      const noShowString = formatNoShowString(noShow);
      return { ...item, noShow: noShowString };
    })
    .filter((item) => item.noShow);
};

const getReservationDateTable = (reportTable) => {
  return reportTable.map((item) => {
    if (!item.attendees || item.attendees.length === 0) {
      const paxString = item.customers
        .map((customerObj) => {
          const key = Object.keys(customerObj)[0];
          const type = key.charAt(0).toUpperCase() + key.slice(1);
          const value = customerObj[key];
          return `${type}: ${value}`;
        })
        .join(", ");
      return { ...item, pax: paxString };
    }
    return item;
  });
};

const chooseReportType = ({ reportTable, selectedRadio }) => {
  if (selectedRadio === "checkinStatus") {
    return getNoShowTable(reportTable);
  }

  if (selectedRadio === "reservationDate") {
    return getReservationDateTable(reportTable);
  }

  return [];
};

export { chooseReportType };
