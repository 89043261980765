import { useState, useEffect } from "react";
import { customFetch } from "../../helpers/customFetch";
import config from "../../config";

export const useShifts = (user, guide, timeRange) => {
  const [shifts, setShifts] = useState([]);
  const [rows, setRows] = useState([]);

  const headers = [
    { key: "date", header: "Date" },
    { key: "tour", header: "Tour" },
    { key: "adults", header: "Adults" },
    { key: "teens", header: "Teens" },
    { key: "children", header: "Children" },
  ];

  const getAttendeeCount = (attendees, type) => {
    const attendeeObj = attendees.find((a) => a[type]);
    return attendeeObj ? attendeeObj[type] : 0;
  };

  useEffect(() => {
    const fetchShifts = async () => {
      const response = await customFetch(
        `${config.apiUrl}/api/shift/guide-time-range`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            guide: guide,
            timeRange: timeRange,
            user: user,
          }),
        }
      );

      const json = await response.json();
      if (response.ok) {
        setShifts(json);
      }
    };

    fetchShifts();
  }, [guide, timeRange, user]);

  useEffect(() => {
    if (!guide) {
      setRows([]);
      return;
    }

    const newRows = shifts.flatMap((shift) => {
      return shift.guides
        .filter((g) => g.name === guide.name)
        .map((guide) => {
          const attendees = guide.attendees || [];
          return {
            id: `${shift._id}-${guide._id}`,
            date: new Date(shift.date).toLocaleDateString("sv"),
            tour: `${shift.time} ${shift.tour}`,
            guideName: guide.name,
            adults: getAttendeeCount(attendees, "Adults"),
            teens: getAttendeeCount(attendees, "Teens"),
            children: getAttendeeCount(attendees, "Children"),
          };
        });
    });

    setRows(newRows);
  }, [shifts, guide]);

  return { shifts, rows, headers };
};

export const useDetailedShifts = (user, guide, timeRange) => {
  const [bookings, setBookings] = useState([]);

  const [rows, setRows] = useState([]);

  const allSources = Array.from(
    new Set(user.company.sources.map((item) => item.label))
  );

  const headers = [
    {
      key: "date",
      header: "Date",
    },
    {
      key: "tour",
      header: "Tour",
    },
    ...allSources.map((source) => ({
      key: source,
      header: source,
    })),
  ];

  const [updatedRows, setUpdatedRows] = useState([]);

  useEffect(() => {
    const fetchBookings = async () => {
      const response = await customFetch(
        `${config.apiUrl}/api/bookings/guide-time-range`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            guide: guide,
            timeRange: timeRange,
            user: user,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const json = await response.json();
      setBookings(json);
    };

    fetchBookings();
  }, [guide, timeRange]);

  useEffect(() => {
    if (!guide) {
      setRows([]);
      return;
    }

    const groupedBookings = {};

    bookings.forEach((booking) => {
      const dateTourKey = `${new Date(booking.date).toLocaleDateString("sv")} ${
        booking.time
      } ${booking.tour}`;

      if (!groupedBookings[dateTourKey]) {
        groupedBookings[dateTourKey] = {};
      }

      if (!groupedBookings[dateTourKey][booking.source]) {
        groupedBookings[dateTourKey][booking.source] = {};
      }

      booking.attendees.forEach((attendee) => {
        const attendeeType = Object.keys(attendee)[0];
        const attendeeCount = Object.values(attendee)[0];

        if (groupedBookings[dateTourKey][booking.source][attendeeType]) {
          groupedBookings[dateTourKey][booking.source][attendeeType] +=
            attendeeCount;
        } else {
          groupedBookings[dateTourKey][booking.source][attendeeType] =
            attendeeCount;
        }
      });
    });

    const newRows = Object.entries(groupedBookings).map(([key, value]) => {
      const [date, ...rest] = key.split(" ");
      const tour = rest.join(" ");
      const sources = Object.entries(value).reduce(
        (acc, [sourceKey, attendees]) => {
          acc[sourceKey] = attendees;
          return acc;
        },
        {}
      );
      return { date, tour, sources };
    });

    setRows(newRows);
  }, [bookings, guide]);

  useEffect(() => {
    if (!rows.length) {
      setUpdatedRows([]);
      return;
    }

    const updatedRows = rows.map((row) => {
      if (!row.sources) {
        return row;
      }

      const sourceEntries = Object.entries(row.sources);
      const sourceData = {};

      sourceEntries.forEach(([source, data]) => {
        const attendees = Object.entries(data)
          .map(([key, value]) => `${key} ${value}`)
          .join(", ");
        sourceData[source] = attendees;
      });

      return {
        id: `${row.date}_${row.tour}`,
        date: row.date,
        tour: row.tour,
        ...sourceData,
      };
    });

    setUpdatedRows(updatedRows);
    console.log("updatedRows", updatedRows);
    console.log("rows", rows);
  }, [rows]);

  useEffect(() => {
    if (JSON.stringify(rows) !== JSON.stringify(updatedRows)) {
      setRows(updatedRows);
    }
  }, [updatedRows]);

  return { bookings, rows, headers };
};
