import React from "react";
import { Select, SelectItem } from "@carbon/react";

const TourSelect = ({ tours, value, onChange }) => (
  <Select
    id="select-tour"
    value={value}
    labelText="Change Tour"
    onChange={onChange}
  >
    {tours.map((tour) => (
      <SelectItem key={tour.id} value={tour.label} text={tour.label} />
    ))}
  </Select>
);

const SourceSelect = ({ sources, value, onChange }) => (
  <Select
    id="select-source"
    value={value}
    labelText="Change Source"
    onChange={onChange}
  >
    {sources.map((source) => (
      <SelectItem key={source.id} value={source.label} text={source.label} />
    ))}
  </Select>
);

export { TourSelect, SourceSelect };
