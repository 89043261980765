import {
  DataTable,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableSelectRow,
} from "@carbon/react";

import { TrashCan } from "@carbon/icons-react";
const TeamDataTable = ({ rowData, headerData, deleteGuide }) => {
  return (
    <>
      <DataTable rows={rowData} headers={headerData} radio>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          selectedRows,
        }) => (
          <TableContainer title="Team Members">
            {/* ... */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader />
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                  <TableHeader></TableHeader> {/* Add an Actions header */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  const isSelected = selectedRows.some(
                    (selectedRow) => selectedRow.id === row.id
                  );

                  return (
                    <TableRow {...getRowProps({ row })}>
                      <TableSelectRow {...getSelectionProps({ row })} />
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>{cell.value}</TableCell>
                      ))}
                      <TableCell>
                        {isSelected && (
                          <TrashCan
                            aria-label="Delete"
                            tabIndex="0"
                            onClick={() => deleteGuide(row.id)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </>
  );
};

export { TeamDataTable };
