import { useState } from "react";
import { Button, TextInput, PasswordInput, Form, Stack } from "@carbon/react";
import { useLogin } from "../../hooks/useLogin";
import { Link } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await login(email, password);
  };

  return (
    <Form className="landing-page" onSubmit={handleSubmit}>
      <Stack gap={8}>
        <div>
          <h1>Log in</h1>
          <p className="landing-page__subheading">
            Don't have an account? <Link to="/signup">Sign up here </Link>
            instead
          </p>
        </div>
        <TextInput
          id="email"
          labelText=""
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@email.com"
          type="email"
        />
        <PasswordInput
          id="password"
          labelText=""
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="P@ssword123"
        />
        <Button
          disabled={isLoading}
          className="landing-page__login--button"
          type="submit"
        >
          Log in
        </Button>
        {error && <div>{error}</div>}
      </Stack>
    </Form>
  );
};

export default LoginPage;
