import { createContext, useReducer, useEffect, useState } from "react";
import config from "../config";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload };
    case "LOGOUT":
      return { user: null };
    default:
      return state;
  }
};

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function isTokenExpired(token) {
  try {
    const decoded = parseJwt(token);
    return Date.now() >= decoded.exp * 1000;
  } catch (err) {
    return false;
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  const checkAccessToken = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token && !isTokenExpired(user.token)) {
      // Access token is valid, set the user in the state

      dispatch({ type: "LOGIN", payload: user });
      return true;
    } else {
      // Access token is expired or not available, try to refresh it
      try {
        const response = await fetch(`${config.apiUrl}/auth/refresh`, {
          method: "GET",
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          // Update the user object with the new access token
          const updatedUser = { ...user, token: data.accessToken };
          localStorage.removeItem("user"); // Remove the old user object
          localStorage.setItem("user", JSON.stringify(updatedUser));
          // Set the user in the state
          dispatch({ type: "LOGIN", payload: updatedUser });
          return true;
        } else {
          // Refresh token failed, log the user out

          localStorage.removeItem("user");
          dispatch({ type: "LOGOUT" });
          return false;
        }
      } catch (error) {
        console.error("Error refreshing access token:", error);
        return false;
      }
    }
  };

  useEffect(() => {
    checkAccessToken().then((isRefreshed) => {
      setIsLoading(false);
    });
  }, []);

  // console.log("AuthContext state:", state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
