import config from "../config";

async function fetchWithTokenRefresh(url, options = {}) {
  const user = JSON.parse(localStorage.getItem("user"));

  // Set the Authorization header with the access token
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${user?.token}`,
  };

  // Perform the API request
  const response = await fetch(url, options);

  // If the response is 401 Unauthorized, try to refresh the access token
  if (response.status === 401) {
    // Refresh the access token
    const refreshResponse = await fetch(`${config.apiUrl}/auth/refresh`, {
      method: "GET",
      credentials: "include",
    });

    if (refreshResponse.ok) {
      const data = await refreshResponse.json();

      // Update the user object with the new access token
      const updatedUser = { ...user, token: data.accessToken };
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(updatedUser));

      // Retry the original API request with the new access token
      options.headers.Authorization = `Bearer ${data.accessToken}`;
      return fetch(url, options);
    } else {
      // Refresh token failed, log the user out
      localStorage.removeItem("user");
      // Redirect the user to the login page
    }
  }

  return response;
}

export { fetchWithTokenRefresh };
