import { useState, useRef, useEffect } from "react";

import config from "../../config";
import {
  Form,
  MultiSelect,
  Stack,
  Button,
  InlineNotification,
} from "@carbon/react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { DatePickerForm } from "../DatePickerForm/DatePickerForm";
import { RadioButtonsForm } from "../RadioButtonForm/RadioButtonsForm";
import ReportHeaderForm from "./ReportHeaderForm";
import { customFetch } from "../../helpers/customFetch";

const headersMapping = {
  checkinStatus: [
    { key: "bookingId", header: "ID" },
    { key: "source", header: "Source" },
    { key: "name", header: "Name" },
    { key: "tour", header: "Tour" },
    { key: "time", header: "Time" },
    { key: "noShow", header: "No Show" },
    { key: "date", header: "Tour Date" },
  ],
  reservationDate: [
    { key: "createdAt", header: "Booked on" },
    { key: "bookingId", header: "ID" },
    { key: "source", header: "Source" },
    { key: "name", header: "Name" },
    { key: "tour", header: "Tour" },
    { key: "date", header: "Tour Date" },
    { key: "pax", header: "Pax" },
  ],
};

const formatDateString = (dateString) => {
  const parts = dateString.split("/");
  const reversed = parts.reverse();
  return reversed.join("-");
};

const fetchBookings = async (
  user,
  formattedStartDate,
  formattedEndDate,
  formattedSources,
  formattedTours,
  setSubmissionError,
  setDataSubmitted,
  setReportTable,
  selectedRadio,
  dateParam // Add dateParam argument
) => {
  dateParam = dateParam === "reservationDate" ? "createdAt" : "date";

  const response = await customFetch(
    `${config.apiUrl}/api/bookings/reports/${dateParam}/${formattedStartDate}/${formattedEndDate}/${formattedSources}/${formattedTours}`,
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }
  );
  const json = await response.json();
  if (response.ok) {
    setSubmissionError(false);
    setDataSubmitted(true);
    setReportTable(json);
  }
};
export default function ReportForm({
  setReportTable,
  selectedRadio,
  setSelectedRadio,
  setHeaders,
  setDataSubmitted,
}) {
  const updateHeaders = (radioValue) => {
    const newHeaders = headersMapping[radioValue];
    if (newHeaders) {
      setHeaders(newHeaders);
    }
  };

  const [tempSelectedRadio, setTempSelectedRadio] = useState(selectedRadio);
  const [selectedDateRange, setSelectedDateRange] = useState(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract one day to get yesterday's date
    const yesterdayISO = currentDate.toISOString();
    return [yesterdayISO, yesterdayISO];
  });
  const { user } = useAuthContext();

  const tours = user.company.tours;

  const setDateRange = (dates) => {
    if (
      dates &&
      dates.length > 1 &&
      JSON.stringify(dates) !== JSON.stringify(selectedDateRange)
    ) {
      setSelectedDateRange(dates);
    }
  };

  const [selectedSources, setSelectedSources] = useState([]);
  const [defaultSelectedSources, setDefaultSelectedSources] = useState([]);

  const sourceDropdownRef = useRef();

  const [selectedTours, setSelectedTours] = useState([]);
  const [defaultSelectedTours, setDefaultSelectedTours] = useState([]);

  const tourDropdownRef = useRef();

  const handleSourcesChange = (selectedItems) => {
    setSelectedSources(selectedItems);
  };

  const handleToursChange = (selectedItems) => {
    setSelectedTours(selectedItems);
  };

  const [submissionError, setSubmissionError] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateHeaders(tempSelectedRadio);

    if (!user) {
      setError("You don't have the required permission");
      return;
    }

    if (selectedSources.length === 0 || selectedTours.length === 0) {
      setSubmissionError(true);
      return;
    }

    //TODO radiobuttons logic should be it's own component
    setSelectedRadio(tempSelectedRadio);

    // Assuming selectedSources is the array you receive on the first render
    let transformedSelectedSources = selectedSources;

    // Check if selectedSources is indeed an array
    if (Array.isArray(selectedSources)) {
      transformedSelectedSources = { selectedItems: selectedSources };
    }

    // Assuming selectedTours is the array you receive on the first render
    let transformedSelectedTours = selectedTours;

    // Check if selectedTours is indeed an array
    if (Array.isArray(selectedTours)) {
      transformedSelectedTours = { selectedItems: selectedTours };
    }
    const formData = {
      startDate: event.target.startDate.value,
      endDate: event.target.endDate.value,
      sources: transformedSelectedSources.selectedItems,
      tours: transformedSelectedTours.selectedItems,
    };

    const formattedStartDate = formatDateString(formData.startDate);
    const formattedEndDate = formatDateString(formData.endDate);
    const formattedSources = formData.sources.map((obj) => obj.label).join("-");
    const formattedTours = formData.tours
      .map((obj) => obj.label.replace(/ /g, "%20"))
      .join("-");

    if (user) {
      fetchBookings(
        user,
        formattedStartDate,
        formattedEndDate,
        formattedSources,
        formattedTours,
        setSubmissionError,
        setDataSubmitted,
        setReportTable,
        selectedRadio,
        tempSelectedRadio // Pass the dateParam here
      );
    }
  };

  const onRadioChange = (event) => {
    setTempSelectedRadio(event.target.value);
  };

  useEffect(() => {
    if (user && user.company.sources.length) {
      const allSources = user.company.sources.map((source) => ({
        id: source.id,
        label: source.label,
      }));

      setDefaultSelectedSources(allSources);
      setSelectedSources(allSources);
    }

    const allTours = user.company.tours.map((tour) => ({
      _id: tour._id,
      id: tour.id,
      label: tour.label,
      color: tour.color,
      flag: tour.flag,
    }));
    setDefaultSelectedTours(allTours);
    setSelectedTours(allTours);
  }, [user]);

  const submitButtonRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (submitButtonRef.current) {
        submitButtonRef.current.click();
      }
    }, 50); // 50 milliseconds = 0.5 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, [defaultSelectedSources]);

  //TODO DatePickerForm still causes issues  when changing second date
  return (
    <Form className="report-form" onSubmit={handleSubmit}>
      <Stack gap={7}>
        <ReportHeaderForm />
        <div>
          <DatePickerForm
            selectedDateRange={selectedDateRange}
            setDates={setDateRange}
          />
        </div>
        <RadioButtonsForm
          selectedRadio={tempSelectedRadio}
          onRadioChange={onRadioChange}
        />

        <MultiSelect
          key={`Sources-${defaultSelectedSources.length}`}
          ariaLabel="Sources MultiSelect"
          id="Sources Multiselect"
          label="Booking Source"
          items={user.company.sources}
          ref={sourceDropdownRef}
          initialSelectedItems={defaultSelectedSources}
          onChange={handleSourcesChange}
        />
        <MultiSelect
          key={`Tours-${defaultSelectedTours.length}`}
          ariaLabel="Tour MultiSelect"
          id="Tour Multiselect"
          label="Tour"
          items={tours}
          ref={tourDropdownRef}
          initialSelectedItems={defaultSelectedTours}
          onChange={handleToursChange}
        />
        {submissionError && (
          <InlineNotification
            className="notification-margin"
            kind="error"
            title="Both Source and Tour need to be selected"
          />
        )}
        {error && <div className="error">{error}</div>}
        <Button ref={submitButtonRef} type="submit">
          Submit
        </Button>
      </Stack>
    </Form>
  );
}
