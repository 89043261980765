import React, { useState, useEffect } from "react";
import { Column, Row, Tooltip } from "@carbon/react";
import { Edit, Information } from "@carbon/react/icons";
import { BookingDropdown } from "../BookingDropdown.js";
import { BookingToggle } from "../BookingToggle/BookingToggle.js";
import { ReactComponent as FamilyIcon } from "../../images/Icons/Family24-18.svg";
import { ReactComponent as UserIcon } from "../../images/Icons/User18.svg";

const Booking = ({
  name,
  pax,
  attendees,
  bookingId,
  source,
  customers,
  _id,
  displaySources,
  updateGuests,
  tour,
  date,
  time,
  phone,
  modifiedBy,
  differences,
  refreshBookings,
  canModify,
  chosenGuide,
}) => {
  const [dropdownOn, setDropdownOn] = useState(false);
  const handleDropdownOn = () => {
    if (canModify) {
      setDropdownOn(!dropdownOn);
    }
  };

  const [toggleClicked] = useState(false);
  // console.log("display", displaySources);
  //Checks if attendees it's an empty array or doesn't exist to return 0
  //  Else, it adds the values of each key
  function countGuests(guestList) {
    return (
      guestList?.reduce((total, guest) => {
        for (let key in guest) {
          total += guest[key];
        }
        return total;
      }, 0) || 0
    );
  }

  const attendeesList = countGuests(attendees);

  const [state, setState] = useState(attendeesList);

  useEffect(() => {
    updateGuests(state);
  });
  // console.log(dropdownOn);
  // console.log(state);
  // console.log(bookingId);

  return (
    <Column lg={16} md={8} sm={4} className="tour-page__r3">
      <Column className="booking-box">
        <Column className="booking">
          <Column className="booking-content">
            <Column className="booking-content-left">
              <p className="booking-name">{name}</p>

              <Row className="booking-text-footer">
                {displaySources && (
                  <p className="booking-text-footer-left">{source} |</p>
                )}
                <button
                  onClick={handleDropdownOn}
                  className="booking-attendance"
                >
                  <div className="booking-pax-icon">
                    {customers.length <= 1 ? <UserIcon /> : <FamilyIcon />}{" "}
                  </div>
                  <p className="booking-pax">
                    {toggleClicked ? pax : state}/{pax}{" "}
                  </p>
                  <div className="booking-edit-icon">
                    <Edit size={21} />
                  </div>
                </button>
              </Row>
            </Column>
            <BookingToggle
              _id={_id}
              customers={customers}
              attendees={attendees}
              bookingId={bookingId === null ? "" : bookingId}
              state={state}
              stateChanger={setState}
              pax={pax}
              canModify={canModify}
              chosenGuide={chosenGuide}
            />
          </Column>
        </Column>
        {modifiedBy ? (
          <div className="modifield-field">
            <span className="font-13">
              Modified by {modifiedBy.split("@")[0]}@
            </span>
            <Tooltip align="bottom" label={differences}>
              <button className="booking-modified-tooltip-trigger">
                <Information />
              </button>
            </Tooltip>
          </div>
        ) : null}
        {dropdownOn && canModify ? (
          <BookingDropdown
            pax={pax}
            customers={customers}
            _id={_id}
            stateChanger={setState}
            closeDropdown={handleDropdownOn}
            tour={tour}
            date={date}
            time={time}
            refreshBookings={refreshBookings}
            phone={phone}
            bookingId={bookingId}
            source={source}
            chosenGuide={chosenGuide}
          />
        ) : null}
      </Column>
    </Column>
  );
};

export { Booking };
