// Helper function to format customers
function formatCustomers(customerState) {
  return Object.keys(customerState)
    .filter((key) => customerState[key] !== 0)
    .map((key) => ({
      [key]: customerState[key],
    }));
}

function formatToAmericanDate(date) {
  let parts = date.split("/");
  return `${parts[1]}/${parts[0]}/${parts[2]}`;
}

function useAmericanFormattedDate(initialDate) {
  let americanFormattedDate;

  if (initialDate.includes("-")) {
    americanFormattedDate = new Date(initialDate);
  } else {
    const americanDate = formatToAmericanDate(initialDate);
    americanFormattedDate = new Date(americanDate);
  }

  return americanFormattedDate;
}

function checkAndSetValidEdits(bookingBeforeEdit, bookingAfterEdit) {
  let differences = {};
  for (let key in bookingBeforeEdit) {
    if (
      Array.isArray(bookingBeforeEdit[key]) &&
      Array.isArray(bookingAfterEdit[key])
    ) {
      // Perform a deep comparison of the arrays
      if (
        JSON.stringify(bookingBeforeEdit[key]) !==
        JSON.stringify(bookingAfterEdit[key])
      ) {
        differences[key] = {
          before: bookingBeforeEdit[key],
          after: bookingAfterEdit[key],
        };
      }
    } else if (key === "date") {
      // Compare the timestamps of the dates
      const dateBefore = new Date(bookingBeforeEdit[key]).getTime();
      const dateAfter = new Date(bookingAfterEdit[key]).getTime();
      if (dateBefore !== dateAfter) {
        differences[key] = {
          before: bookingBeforeEdit[key],
          after: bookingAfterEdit[key],
        };
      }
    } else if (bookingBeforeEdit[key] !== bookingAfterEdit[key]) {
      differences[key] = {
        before: bookingBeforeEdit[key],
        after: bookingAfterEdit[key],
      };
    }
  }

  return {
    hasDifferences: Object.keys(differences).length > 0, // Return true if there are any differences, false otherwise
    differences: differences, // Return the differences object
  };
}

const formatArrayOrObject = (value) => {
  if (Array.isArray(value)) {
    return value.map((item) => JSON.stringify(item, null, 2)).join(", ");
  } else if (typeof value === "object") {
    return JSON.stringify(value, null, 2);
  }
  return value;
};

const formatDate = (date) => {
  return date.split("T")[0];
};

const capitalizeKey = (key) => {
  return key.charAt(0).toUpperCase() + key.slice(1);
};

function formatDifferences(differences) {
  let formattedDifferences = "";

  for (let key in differences) {
    if (!differences[key].before || !differences[key].after) continue;

    let before = formatArrayOrObject(differences[key].before);
    let after = formatArrayOrObject(differences[key].after);

    if (key === "date") {
      before = formatDate(before);
      after = formatDate(after);
    }

    let capitalizedKey = capitalizeKey(key);
    formattedDifferences += `${capitalizedKey} changed from:\n${before}\nto:\n${after}\n\n`;
  }

  return formattedDifferences.trim();
}

export {
  formatCustomers,
  formatToAmericanDate,
  useAmericanFormattedDate,
  checkAndSetValidEdits,
  formatDifferences,
};
