const ReportHeaderForm = () => {
  return (
    <div>
      <h1 className="form-heading">Generate Report</h1>
      <p>Choose a date, report type and custom fields to generate a report.</p>
    </div>
  );
};

export default ReportHeaderForm;
