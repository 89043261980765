import React, { useState } from "react";
import {
  Grid,
  Column,
  Button,
  TextInput,
  PasswordInput,
  Form,
  InlineNotification,
} from "@carbon/react";
import { useAuthContext } from "../../hooks/useAuthContext";
import config from "../../config";
import GuidesList from "../../helpers/getGuidesHelper";
import { customFetch } from "../../helpers/customFetch";

const GuidesPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState("initial");

  const { user } = useAuthContext();
  const company = user.company._id;
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Submitting email and password:", email, password, company);

    try {
      const response = await customFetch(`${config.apiUrl}/api/user/addguide`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ name, email, password, company }),
      });
      const data = await response.json();
      if (!response.ok) {
        setError(data.error);
        setSubmissionStatus("error");
      } else {
        setSubmissionStatus("success");

        //Clean the state
        setEmail("");
        setPassword("");
        // Trigger the guideAdded event after a successful addition
        const event = new CustomEvent("guideAdded");
        window.dispatchEvent(event);
      }
      console.log("Response data:", data);
    } catch (error) {
      console.error("Error during fetch:", error);
    }
  };

  return (
    <>
      <h1 className="h1-form">Manage Team</h1>
      <Form className="add-guide-form" onSubmit={handleSubmit}>
        <h4 className="h4-form">Add Guide</h4>
        <Grid fullWidth>
          <Column sm={4} md={3} lg={3} className="add-guide-column">
            <TextInput
              id="name"
              type="text"
              labelText=""
              value={name}
              placeholder="John"
              onChange={(e) => setName(e.target.value)}
            />
          </Column>
          <Column sm={4} md={3} lg={3} className="add-guide-column">
            <TextInput
              id="email"
              type="email"
              labelText=""
              value={email}
              placeholder="john.example@email.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Column>
          <Column sm={4} md={3} lg={3} className="add-guide-column">
            <PasswordInput
              id="password"
              labelText=""
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="P@ssword123"
            />
          </Column>
          <Column sm={4} md={2} lg={2} className="add-guide-column">
            <Button type="submit">Add guide</Button>
          </Column>
        </Grid>
        {submissionStatus === "error" && (
          <InlineNotification
            className="notification-margin"
            kind="error"
            title={error}
          />
        )}
        {submissionStatus === "success" && (
          <InlineNotification
            kind="success"
            title="New guide successfully added"
          />
        )}
      </Form>
      <GuidesList />
    </>
  );
};

export default GuidesPage;
