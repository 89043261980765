import NumberPicker from "../NumberInput/NumberInput";
import AttendanceButton from "../AttendanceButton/AttendanceButton";

function CustomerType({ item, index, update }) {
  const itemValue = parseInt(Object.values(item));
  const customerType = Object.keys(item)[0];

  if (itemValue + 1 > 5) {
    return (
      <li key={customerType}>
        <div className="buttons">
          <NumberPicker
            item={item}
            update={(num) => update(num, index, customerType)}
            customerType={customerType}
          />
        </div>
      </li>
    );
  } else {
    return (
      <li key={customerType}>
        <div className="buttons">
          <AttendanceButton
            item={item}
            update={(num) => update(num, index, customerType)}
            customerType={customerType}
          />
        </div>
      </li>
    );
  }
}

export default CustomerType;
