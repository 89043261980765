import React, { useState, useEffect } from "react";
import { Column, Row } from "@carbon/react";
import { ArrowRight, UserMultiple } from "@carbon/react/icons";
import { useParams, Link } from "react-router-dom";

const BookingsTourLinkBox = (props) => {
  const { day } = useParams();
  const [FlagIcon, setFlagIcon] = useState(null);

  // console.log(props);
  useEffect(() => {
    if (props.flag && props.flag !== "none") {
      import(`../../images/flags/${props.flag}.svg`)
        .then((icon) => {
          setFlagIcon(icon.default);
        })
        .catch((error) => {
          console.error(`Error loading flag icon: ${error}`);
        });
    } else {
      setFlagIcon(null);
    }
  }, [props.flag]);
  return (
    <Row className="link-booking">
      <Link
        to={`/bookings/date/${day}/${props.time}/${props.tour}`}
        className="text-decoration-none"
      >
        <Row className="bx--grid link-box-container">
          <Column
            className={`booking-color bx--col-1 color-${props.color}`}
          ></Column>
          <Column className="link-box-content bx--col">
            <Row className="link-box-content-row">
              <Column className="link-box-content-text">
                <Row className="link-box-content-text-upper">
                  <p className="tour-time">{props.time}</p>
                  <p className="tour-title">
                    {FlagIcon && (
                      <img
                        className="flag-icon"
                        src={FlagIcon}
                        alt={`${props.flag} flag`}
                      />
                    )}
                    {props.tour}
                  </p>
                </Row>
                {/*GUIDES SHOULD BE ADDED HERE */}
                <p className="link-box-content-text-lower">
                  <UserMultiple size={16} />
                  {props.pax}
                </p>
              </Column>
              <div className="arrow-right-details">
                <ArrowRight size={24} />
              </div>
            </Row>
          </Column>
        </Row>
      </Link>
    </Row>
  );
};

export { BookingsTourLinkBox };
