import React, { useState } from "react";
import { HeaderMenuItem, HeaderGlobalAction } from "@carbon/react";
import { UserAvatar, Logout } from "@carbon/react/icons";
import { useLogout } from "../../hooks/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Link } from "react-router-dom";

function ProfileMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const { logout } = useLogout();
  const { user } = useAuthContext();

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  const handleClick = () => {
    logout();
  };

  const userEmail = user && user.email ? user.email : "";
  const maxLength = 20;
  const trimmedEmail =
    userEmail.length > maxLength
      ? userEmail.substring(0, maxLength - 3) + "..."
      : userEmail;

  return (
    <>
      <HeaderGlobalAction
        aria-label="Profile"
        onClick={toggleMenu}
        isActive={isOpen}
      >
        <UserAvatar size={24} />
      </HeaderGlobalAction>
      {isOpen && (
        <>
          {user && (
            <div className="profile-menu" onClick={toggleMenu}>
              <HeaderMenuItem className="profile-dropdown user-email">
                {trimmedEmail}
              </HeaderMenuItem>
              <HeaderMenuItem
                href="/account"
                className="profile-dropdown padding-right"
              >
                Account
              </HeaderMenuItem>
              <HeaderMenuItem
                className="profile-dropdown"
                onClick={handleClick}
              >
                Logout <Logout size={16} className="profile-dropdown-icon" />
              </HeaderMenuItem>
            </div>
          )}
          {/* TODO only have the option to logout when user is already logged in */}
          {!user && (
            <div className="profile-menu padding-right" onClick={toggleMenu}>
              <HeaderMenuItem
                element={Link}
                to="/login"
                className="profile-dropdown"
              >
                Login
              </HeaderMenuItem>
              <HeaderMenuItem
                element={Link}
                to="/signup"
                className="profile-dropdown"
              >
                Signup
              </HeaderMenuItem>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ProfileMenu;
