import React, { useState, useEffect } from "react";
import { Add, Subtract } from "@carbon/react/icons";

const NumberPicker = ({ item, update, customerType }) => {
  const [customerTypeAttendance, setcustomerTypeAttendance] = useState(0);

  //TODO check missing dependencies here
  useEffect(() => {
    update(0, item.index, customerType);
  }, []);

  const customerTypeCapitalized =
    customerType.charAt(0).toUpperCase() + customerType.slice(1);
  const customerPax = Number(Object.values(item));

  const minValue = 0;
  const maxValue = customerPax;

  const handleDecrease = () => {
    if (customerTypeAttendance > minValue) {
      setcustomerTypeAttendance((prevValue) => prevValue - 1);
      update(customerTypeAttendance - 1, item.index, customerType);
    }
  };

  const handleIncrease = () => {
    if (customerTypeAttendance < maxValue) {
      setcustomerTypeAttendance((prevValue) => prevValue + 1);
      update(customerTypeAttendance + 1, item.index, customerType);
    }
  };

  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value);
    if (isNaN(newValue)) {
      // If the new value is not a number, set customerTypeAttendance to 0
      setcustomerTypeAttendance(0);
      update(0, item.index, customerType);
      return;
    }

    if (newValue >= minValue && newValue <= maxValue) {
      setcustomerTypeAttendance(newValue);
    }
    update(newValue, item.index, customerType);
  };

  return (
    <div className="group-attendance-buttons">
      <p className="label">{`${customerTypeCapitalized} ${customerTypeAttendance}/${Object.values(
        item
      )}`}</p>
      <div className="number-picker-frame">
        <div className="number-picker">
          <div>
            <button
              type="button"
              className="number-picker-step-button"
              onClick={handleDecrease}
            >
              <Subtract className="number-input-icon" size={20} />
            </button>
          </div>
          <div>
            <input
              className="editable-input"
              type="number"
              value={customerTypeAttendance}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <button
              type="button"
              className="number-picker-step-button"
              onClick={handleIncrease}
            >
              <Add className="number-input-icon" size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberPicker;
