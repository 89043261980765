import React from "react";
import { BookingsTourLinkBox } from "../components/BookingsTourLinkBox";

function Shift(tour, time, pax, color, flag) {
  this.tour = tour;
  this.time = time;
  this.pax = pax;
  this.color = color || "default";
  this.flag = flag || "none";
}

function getMinutesDifference(date1, date2) {
  const diffInMilliseconds = date1 - date2;
  return Math.floor(diffInMilliseconds / 1000 / 60);
}

function getShiftDate(timeString) {
  const currentDate = new Date();
  const [hours, minutes] = timeString.split(":");
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    hours,
    minutes
  );
}

function createBookingMap(bookings, tours) {
  const bookingMap = new Map();

  bookings.forEach((booking) => {
    const key = `${booking.tour}-${booking.time}`;
    const existingShift = bookingMap.get(key);

    if (existingShift) {
      existingShift.pax += booking.pax;
    } else {
      const tour = tours.find((tour) => tour.label === booking.tour);
      const newShift = new Shift(
        booking.tour,
        booking.time,
        booking.pax,
        tour?.color,
        tour?.flag
      );
      bookingMap.set(key, newShift);
    }
  });

  return bookingMap;
}

function sortAndMapShifts(bookingMap) {
  return Array.from(bookingMap.values())
    .sort((a, b) => {
      if (a.time < b.time) return -1;
      if (a.time > b.time) return 1;
      if (a.tour < b.tour) return -1;
      if (a.tour > b.tour) return 1;
      return 0;
    })
    .map((shift) => (
      <BookingsTourLinkBox key={shift.time + shift.tour} {...shift} />
    ));
}
function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

function getDailyTours(bookings, tours, user) {
  const bookingMap = createBookingMap(bookings, tours);
  if (
    user.role === "Guide" &&
    (!user.company || user.company.hideBookings !== false)
  ) {
    const currentTime = new Date();
    const filteredBookingMap = new Map();
    let canSeeTours = false;
    for (let [key, shift] of bookingMap.entries()) {
      const shiftTime = getShiftDate(shift.time);
      const diffInMinutes = getMinutesDifference(currentTime, shiftTime);
      const bookingDate = new Date(bookings[0].date);
      if (
        isSameDay(bookingDate, currentTime) &&
        ((diffInMinutes <= user.company.afterTime && diffInMinutes > 0) ||
          (diffInMinutes >= -user.company.beforeTime && diffInMinutes < 0))
      ) {
        filteredBookingMap.set(key, shift);
        canSeeTours = true;
      }
    }

    if (!canSeeTours) {
      return (
        <p className="no-bookings-message">Only Managers can see these tours</p>
      );
    }

    return sortAndMapShifts(filteredBookingMap);
  }
  return sortAndMapShifts(bookingMap);
}

function getSortedTours(bookings) {
  const sortedData =
    bookings &&
    bookings.sort((a, b) => {
      if (a.time < b.time) return -1;
      if (a.time > b.time) return 1;
      if (a.tour < b.tour) return -1;
      if (a.tour > b.tour) return 1;
      return 0;
    });
  return sortedData;
}

function getAfterMinutesDifference(date1, date2time, day) {
  const dateTimeString = new Date(`${day}T${date2time}`);
  const diffInMilliseconds = date1 - dateTimeString;
  return Math.floor(diffInMilliseconds / 1000 / 60);
}

function canModify(user, tourTime, day) {
  if (user.company.unlockBookings === true) {
    return true;
  }
  if (user.role === "Manager") {
    return true;
  }
  if (user.role === "Guide") {
    const currentTime = new Date();
    const diffInMinutes = getAfterMinutesDifference(currentTime, tourTime, day);
    // Allow modification if the tour hasn't happened yet or for up to 30 minutes after the tour starts
    if (diffInMinutes <= 30) {
      return true;
    } else {
      // Restrict modification if more than 30 minutes after the tour starts
      return false;
    }
  }

  // Default to false for other roles or undefined conditions
  return false;
}

export { getDailyTours, getSortedTours, canModify };
