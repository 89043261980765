import { useEffect, useState } from "react";
import config from "../config";
import { useAuthContext } from "../hooks/useAuthContext";
import { TeamDataTable } from "../components/TeamDataTable/TeamDataTable";
import { InlineNotification } from "@carbon/react";
import { customFetch } from "./customFetch";

const GuidesList = () => {
  const [guides, setGuides] = useState([]);
  const { user } = useAuthContext();
  const [error, setError] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState("initial");

  const deleteGuide = async (userId) => {
    try {
      const response = await customFetch(
        `${config.apiUrl}/api/user/guides/${userId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.ok) {
        setGuides((prevGuides) =>
          prevGuides.filter((guide) => guide._id !== userId)
        );
        setSubmissionStatus("success");
      } else {
        setError("You don't have permission to perform that action");
        setSubmissionStatus("error");
      }
    } catch (error) {
      console.error("Error deleting guide:", error.message);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await customFetch(
        `${config.apiUrl}/api/user/users/company`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      if (response.ok) {
        const json = await response.json();
        setGuides(json);
      }
    };
    const handleGuideAdded = () => {
      // Refresh the guides list
      fetchUsers();
    };

    // Listen for the guideAdded event
    window.addEventListener("guideAdded", handleGuideAdded);

    if (user) {
      fetchUsers();
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("guideAdded", handleGuideAdded);
    };
  }, [user]);

  const rowData = guides.map((guide) => ({
    id: guide._id,
    email: guide.email,
    role: guide.role,
  }));

  const headerData = [
    { key: "email", header: "Email" },
    { key: "role", header: "Role" },
  ];

  return (
    <div>
      {guides.length > 0 && (
        <>
          {submissionStatus === "error" && (
            <InlineNotification
              className="notification-margin"
              kind="error"
              title={error}
            />
          )}
          {submissionStatus === "success" && (
            <InlineNotification
              kind="success"
              title="Guide succesfully deleted"
            />
          )}
          <TeamDataTable
            rowData={rowData}
            headerData={headerData}
            deleteGuide={deleteGuide}
          />
        </>
      )}
    </div>
  );
};

export default GuidesList;
