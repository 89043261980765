import ChangePassword from "../../components/ChangePassword/ChangePassword";
import TourCustomiser from "../../components/TourCustomiser/TourCustomiser";
import ManagerOnly from "../../helpers/managersOnly";
const AccountPage = () => {
  return (
    <>
      <div>
        <ChangePassword />
        <ManagerOnly>
          <TourCustomiser />
        </ManagerOnly>
      </div>
    </>
  );
};

export default AccountPage;
