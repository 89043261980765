import React from "react";
import { useState, useEffect } from "react";
import { Column, IconButton, Row } from "@carbon/react";
import { Copy, AddComment, UserMultiple, Locked } from "@carbon/react/icons";

const BookingsTourHeading = ({
  time,
  tour,
  totalGuests,
  totalPax,
  detailedTotalPax,
  copyBookingsToClipboard,
  handleClick,
  color,
  flag,
  shift,
  setAttendeesMismatch,
  canModify,
}) => {
  const [FlagIcon, setFlagIcon] = useState(null);

  useEffect(() => {
    if (flag !== "none") {
      import(`../../images/flags/${flag}.svg`)
        .then((icon) => {
          setFlagIcon(icon.default);
        })
        .catch((error) => {
          console.error(`Error loading flag icon: ${error}`);
        });
    } else {
      setFlagIcon(null);
    }
  }, [flag]);

  function Guide({ guide }) {
    const attendees = guide.attendees.flatMap((attendee, index) => {
      const entries = Object.entries(attendee)
        .filter(([key, value]) => value !== 0) // Remove entries with a value of 0
        .map(([key, value]) => `${value} ${key.substring(0, 3)}`); // Map entries to strings
      return entries;
    });
    return (
      <div key={guide._id}>
        {guide.name}:{" "}
        <span>
          {attendees
            .map((attendee, index) => {
              // Add a comma only when the current index is not the last one
              const comma = index < attendees.length - 1 ? ", " : "";
              return `${attendee}${comma}`;
            })
            .join("")}
        </span>{" "}
      </div>
    );
  }

  function totalGuidesAttendees(guides) {
    let totalAttendees = 0;

    guides.forEach((guide) => {
      guide.attendees.forEach((attendee) => {
        Object.values(attendee).forEach((value) => {
          totalAttendees += value;
        });
      });
    });

    return totalAttendees;
  }

  useEffect(() => {
    const totalAttendees =
      shift && shift.guides ? totalGuidesAttendees(shift.guides) : 0;
    setAttendeesMismatch(totalGuests !== totalAttendees);
  }, [shift, totalGuests]);
  return (
    <Row className="link-booking">
      <Row className="bx--grid tour-heading-container ">
        <Row className={`booking-color-row color-${color}`}></Row>
        <Column className="tour-heading-content">
          <Row className="link-box-content-row">
            <Column className="link-box-content-text">
              <Row className="link-box-content-text-upper">
                <p className="tour-time">{time}</p>
                <p className="tour-title">
                  {FlagIcon && (
                    <img
                      className="flag-icon"
                      src={FlagIcon}
                      alt={`${flag} flag`}
                    />
                  )}
                  {tour}
                  {!canModify && <Locked size={20} />}
                </p>
              </Row>
              {/*GUIDES SHOULD BE ADDED HERE */}
              <p className="link-box-content-text-lower">
                <UserMultiple size={16} />
                {totalGuests}/{totalPax}
                <span>
                  {detailedTotalPax && (
                    <>
                      <span className="spacing-04-right spacing-03-left">
                        |
                      </span>
                      <span>Total: {detailedTotalPax}</span>
                    </>
                  )}
                </span>
              </p>
              <div className="tour-heading-guide-group">
                {shift &&
                  shift.guides &&
                  shift.guides.map((guide) => (
                    <Guide key={guide._id} guide={guide} className="guide" />
                  ))}
              </div>
            </Column>
            <Row className="right-icons">
              <IconButton
                align="top"
                label="Add note"
                className="cds--btn--icon-only reversed-color"
                onClick={handleClick}
              >
                <AddComment size={20} />
              </IconButton>
              <IconButton
                align="top"
                label="Copy pax"
                className="cds--btn--icon-only reversed-color"
                onClick={copyBookingsToClipboard}
              >
                <Copy size={20} />
              </IconButton>
            </Row>
          </Row>
        </Column>
      </Row>
    </Row>
  );
};

export { BookingsTourHeading };
