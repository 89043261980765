import { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import config from "../../config";
import { customFetch } from "../../helpers/customFetch";

// Custom hook for time calculation
const useCalculateTime = (time) => {
  const selectedTourTimeParts = time.split(":");
  const selectedTourDate = new Date();
  selectedTourDate.setHours(
    parseInt(selectedTourTimeParts[0]),
    parseInt(selectedTourTimeParts[1])
  );

  // Calculate start and end times for 15 minutes range
  const startTimeDate = new Date(selectedTourDate.getTime() - 15 * 60 * 1000); // subtract 15 minutes
  const endTimeDate = new Date(selectedTourDate.getTime() + 15 * 60 * 1000); // add 15 minutes

  // Convert start and end times back to 24-hour format
  const startTime = `${startTimeDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${startTimeDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
  const endTime = `${endTimeDate
    .getHours()
    .toString()
    .padStart(2, "0")}:${endTimeDate.getMinutes().toString().padStart(2, "0")}`;

  return { startTime, endTime };
};

const useFetchNearbyTours = (day, startTime, endTime, user, setNearbyTours) => {
  const fetchNearbyTours = useCallback(async () => {
    // Fetch bookings within the time range
    const response = await customFetch(
      `${config.apiUrl}/api/bookings/nearbytours/${day}/${startTime}/${endTime}`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();
    if (response.ok) {
      setNearbyTours(json);
    }
  }, [day, startTime, endTime, user, setNearbyTours]);

  useEffect(() => {
    fetchNearbyTours();
  }, [fetchNearbyTours]);
};

const FetchNearbyTours = ({ setNearbyTours }) => {
  const { day, time } = useParams();
  const { user } = useAuthContext();

  const { startTime, endTime } = useCalculateTime(time);

  useFetchNearbyTours(day, startTime, endTime, user, setNearbyTours);

  return null;
};

export default FetchNearbyTours;
