import { Column } from "@carbon/react";
import { useParams, Link } from "react-router-dom";
import { Time } from "@carbon/react/icons";

const BookingsTourLink = ({ displayedNearbyTours }) => {
  const { day, tour } = useParams();
  const selectedTour = `${tour}`;

  const nearbyTours = displayedNearbyTours.filter(
    (item) => item.tour !== selectedTour
  );

  // Group items by time
  const groupedData = nearbyTours.reduce((groups, item) => {
    const groupKey = item.time;
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(item);
    return groups;
  }, {});
  return (
    <div>
      {Object.entries(groupedData).map(([time, items], index) => {
        const uniqueTours = [...new Set(items.map((item) => item.tour))];
        return (
          <Column href="#" key={time}>
            <p className="time-nearby-tours">
              <Time className="time-icon" size={16} />
              {time}:
              {uniqueTours.map((tour, i) => {
                let tourToDisplay = tour;
                if (tour.length > 15) {
                  // truncate each word in tour to the first three characters and remove parentheses
                  tourToDisplay = tour
                    .split(" ")
                    .map((word) =>
                      word.length > 3 ? word.substring(0, 3) : word
                    )
                    .join(" ")
                    .replace(/\(|\)/g, "");
                }
                return [
                  i > 0 && ", ",
                  <Link
                    className="nearby-tour-link"
                    key={i}
                    to={`/bookings/date/${day}/${time}/${tour}`}
                  >
                    {tourToDisplay}
                  </Link>,
                ];
              })}
            </p>
          </Column>
        );
      })}
    </div>
  );
};

export { BookingsTourLink };
