import { useAuthContext } from "../hooks/useAuthContext";

function ManagerOnly({ children }) {
  const { user } = useAuthContext();

  // Check if user is a manager
  if (user.role !== "Manager") {
    return null; // or redirect to another page
  }

  return children;
}

export default ManagerOnly;
