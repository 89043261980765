import { useState } from "react";

import ReportForm from "../../components/ReportForm/ReportForm";
import { ReportDataTable } from "../../components/ReportDataTable/ReportDataTable";
import { chooseReportType } from "../../helpers/ChooseReportType";

const ReportsPage = () => {
  const [headers, setHeaders] = useState([]);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [reportTable, setReportTable] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("checkinStatus");

  const ReportTypeTable = chooseReportType({ reportTable, selectedRadio });

  const formattedReportTable = ReportTypeTable.map((item) => ({
    id: item._id,
    ...item,
    date: item.date.substring(0, 10),
    createdAt: item.createdAt.substring(0, 10),
  }));

  return (
    <>
      <ReportForm
        setReportTable={setReportTable}
        selectedRadio={selectedRadio}
        setSelectedRadio={setSelectedRadio}
        setDataSubmitted={setDataSubmitted}
        setHeaders={setHeaders}
      />
      <ReportDataTable
        reportTable={formattedReportTable}
        headers={headers}
        dataSubmitted={dataSubmitted}
      />
    </>
  );
};

export default ReportsPage;
