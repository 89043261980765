// Function to sum values by key across all bookings
export const sumValuesByKey = (bookings) => {
  return bookings.reduce((acc, booking) => {
    booking.attendees.forEach((attendee) => {
      for (let key in attendee) {
        if (acc[key]) {
          acc[key] += attendee[key];
        } else {
          acc[key] = attendee[key];
        }
      }
    });
    return acc;
  }, {});
};

// Function to format the result
export const formatResult = (sums) => {
  let result = "";
  for (let key in sums) {
    if (sums[key] !== 0) {
      result += `${sums[key]} ${key.slice(0, 3)} , `;
    }
  }
  return result.slice(0, -3); // Remove trailing comma and space
};
