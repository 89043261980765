import { DatePicker, DatePickerInput } from "@carbon/react";

const DatePickerForm = ({ selectedDateRange, setDates }) => {
  // Convert selectedDateRange from ISO strings to Date objects for the DatePicker
  const dateRangeAsDates = selectedDateRange.map(
    (dateStr) => new Date(dateStr)
  );

  const handleChange = (dates) => {
    // Convert dates from Date objects back to ISO strings
    const datesAsISOStrings = dates.map((date) => date.toISOString());
    setDates(datesAsISOStrings);
  };

  return (
    <div>
      <p className="form-info">Dates</p>
      <DatePicker
        datePickerType="range"
        dateFormat="d/m/Y"
        value={dateRangeAsDates} // Use the converted Date objects
        onChange={handleChange}
      >
        <DatePickerInput
          required
          id="startDate"
          labelText=""
          placeholder="dd/mm/yyyy"
          autocomplete="off"
        />
        <DatePickerInput
          required
          id="endDate"
          labelText=""
          placeholder="dd/mm/yyyy"
          autocomplete="off"
        />
      </DatePicker>
    </div>
  );
};

const DatePickerFormSingle = ({ setDay }) => {
  const today = new Date().toLocaleDateString("uk");

  return (
    <div>
      <DatePicker
        dateFormat="d/m/Y"
        datePickerType="single"
        onChange={(date) => {
          // DatePicker returns an array of date strings
          const dateStr = date[0];
          if (dateStr) {
            // Create a new Date object
            const dateObj = new Date(dateStr).toLocaleDateString("en-CA");
            // Format the date
            setDay(dateObj);
          }
        }}
      >
        <DatePickerInput
          id="date-picker-calendar-id"
          placeholder={today}
          labelText="Choose date (default today)"
          type="text"
          autocomplete="off"
        />
      </DatePicker>
    </div>
  );
};

export { DatePickerForm, DatePickerFormSingle };
