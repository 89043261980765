export default function getPrevOrNextDay(day, option, timezone) {
  const localOffset = new Date().getTimezoneOffset();

  const date =
    localOffset <= 0
      ? new Date(day + "T00:00:00Z")
      : new Date(day + "T00:00:00");

  if (option === "prevDay") {
    date.setUTCDate(date.getUTCDate() - 1);
  } else if (option === "nextDay") {
    date.setUTCDate(date.getUTCDate() + 1);
  }

  const formattedDate = new Intl.DateTimeFormat("sv-SE", {
    timeZone: timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);

  return formattedDate;
}
