import { useState } from "react";
import { Button, TextInput, PasswordInput, Form, Stack } from "@carbon/react";
import { useSignup } from "../../hooks/useSignup";
import { Link } from "react-router-dom";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup, error, isLoading } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await signup(email, password);
  };
  return (
    <Form className="landing-page" onSubmit={handleSubmit}>
      <Stack gap={8}>
        <div>
          <h1>Sign up</h1>
          <p className="landing-page__subheading">
            Already have an account? <Link to="/login">Login here</Link> instead
          </p>
        </div>
        <TextInput
          id="email"
          labelText=""
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@email.com"
          type="email"
        />
        <PasswordInput
          id="password"
          labelText=""
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="P@ssword123"
        />
        <Button
          disabled={isLoading}
          className="landing-page__login--button"
          type="submit"
        >
          Sign up
        </Button>
        {error && <div>{error}</div>}
      </Stack>
    </Form>
  );
};

export default SignupPage;
