import "./app.scss";
import { Content, Theme, Loading } from "@carbon/react";
import MainHeader from "./components/MainHeader/MainHeader";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BookingsPage from "./pages/BookingsPage";
import ReportsPage from "./pages/ReportsPage";
import ToursPage from "./pages/ToursPage";
import GuidesPage from "./pages/GuidesPage";
import AccountPage from "./pages/AccountPage";
import BillingPage from "./pages/BillingPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import { useAuthContext } from "./hooks/useAuthContext";
import FeesPage from "./pages/FeesPage";
import ShiftsPage from "./pages/ShiftsPage";

function App() {
  const { user, isLoading } = useAuthContext();

  const day = new Date().toLocaleDateString("sv");

  // Need to wait until useEffect loads user to be able to render page properly
  //  and not redirect to login on first null user
  if (isLoading) {
    return (
      <div className="loading-container">
        <Loading withOverlay={false} />
      </div>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Theme theme="g100">
          <MainHeader />
        </Theme>
        <Content>
          <Routes>
            <Route
              path="/"
              element={
                user ? (
                  <Navigate to={`/bookings/date/${day}`} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />{" "}
            <Route
              path="/login"
              element={!user ? <LoginPage /> : <Navigate to="/" />}
            />
            <Route
              path="/signup"
              element={!user ? <SignupPage /> : <Navigate to="/" />}
            />
            <Route
              path="/bookings/date/:day"
              element={user ? <BookingsPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/bookings/date/:day/:time/:tour"
              element={user ? <ToursPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/reports"
              element={user ? <ReportsPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/fees"
              element={user ? <FeesPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/guides"
              element={user ? <GuidesPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/account"
              element={user ? <AccountPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/billing"
              element={user ? <BillingPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/shifts"
              element={user ? <ShiftsPage /> : <Navigate to="/login" />}
            />
          </Routes>{" "}
        </Content>
      </BrowserRouter>
    </>
  );
}

export default App;
