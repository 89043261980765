import { useState, useRef, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Phone } from "@carbon/react/icons";
import CustomerType from "./CustomerType";
import {
  InlineNotification,
  Button,
  Modal,
  Row,
  Column,
  TimePicker,
  DatePicker,
  DatePickerInput,
  Select,
  SelectItem,
  NumberInput,
  TextInput,
} from "@carbon/react";
import config from "../../config";
import { customFetch } from "../../helpers/customFetch";
import ModalStateManager from "../../pages/ToursPage/ModalStateManager";
import { TourSelect, SourceSelect } from "./TourSelect";
import {
  formatCustomers,
  useAmericanFormattedDate,
  checkAndSetValidEdits,
  formatDifferences,
} from "./EditBookingHelpers";

const BookingDropdown = ({
  customers,
  _id,
  stateChanger,
  closeDropdown,
  tour,
  time,
  date,
  refreshBookings,
  phone,
  bookingId,
  source,
  chosenGuide,
}) => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [submissionError, setSubmissionError] = useState(false);
  const [arr, setArr] = useState([]);
  const [total, setTotal] = useState(0);
  const { user } = useAuthContext();
  const update = (num, index, customerType) => {
    const newData = [...attendanceData];
    const itemData = newData[index] || {};
    itemData[customerType] = num;
    newData[index] = itemData;
    setAttendanceData(newData);
    arr[index] = num;
    setArr(arr);
    const sum = arr.reduce((a, b) => a + b, 0);
    setTotal(sum);
  };

  const customerTypes = customers.map((item, index) => (
    <CustomerType
      key={Object.keys(item)[0]}
      item={item}
      index={index}
      update={update}
    />
  ));

  const handleCheckin = (e) => {
    e.preventDefault();
    // Check if all customer types have a selected value
    const hasSelectedValue = customers.every((item, index) => {
      const itemData = attendanceData[index] || {};
      return Object.keys(itemData).length > 0;
    });

    if (hasSelectedValue) {
      customFetch(`${config.apiUrl}/api/bookings/${_id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify({
          attendees: attendanceData,
          _id: _id,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setSubmissionError(false);
          stateChanger(total);
          closeDropdown();
        })
        .catch((error) => console.log(error));
    } else {
      setSubmissionError(true);
    }
  };

  const editButton = useRef();
  // const [open, setOpen] = useState(false);
  const tours = user.company.tours;
  const sources = user.company.sources;
  const defaultTourLabel = tours.find((t) => t.label === tour)?.label || "";
  const defaultSourceLabel =
    sources.find((t) => t.label === source)?.label || "";
  const [selectedTour, setSelectedTour] = useState(defaultTourLabel);
  const [selectedSource, setSelectedSource] = useState(defaultSourceLabel);
  const [selectedTime, setSelectedTime] = useState(time);
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedCancel, setSelectedCancel] = useState(false);
  const [tempPhone, setTempPhone] = useState(phone ?? "");
  const [selectedPhone, setSelectedPhone] = useState(phone ?? "");
  const [isNumberInputValid, setIsNumberInputValid] = useState(true);
  const defaultCustomer = { Adults: 0, Children: 0 };
  const mergedCustomers = customers.reduce(
    (acc, customer) => ({ ...acc, ...customer }),
    defaultCustomer
  );
  const [customerState, setCustomerState] = useState(mergedCustomers);
  const dateObj = new Date(date);
  const bookingBeforeEdit = {
    company: user.company.name,
    customers: customers,
    time: time,
    tour: defaultTourLabel,
    source: defaultSourceLabel,
    date: dateObj,
    cancelled: false,
    phone: phone,
  };

  // console.log("customerState", customerState);
  const formattedCustomers = formatCustomers(customerState);
  const americanFormattedDate = useAmericanFormattedDate(selectedDate);
  const edittedPax = Object.values(customerState).reduce(
    (a, b) => Number(a) + Number(b),
    0
  );
  const bookingAfterEdit = {
    company: user.company.name,
    customers: formattedCustomers,
    time: selectedTime,
    tour: selectedTour,
    source: selectedSource,
    date: americanFormattedDate,
    phone: selectedPhone,
    cancelled: selectedCancel,
  };

  const handleSaveChanges = async (e) => {
    const bookingAfterEditServer = {
      // Added Pax inside handleSaveChanges
      company: user.company.name,
      customers: formattedCustomers,
      pax: edittedPax,
      time: selectedTime,
      tour: selectedTour,
      source: selectedSource,
      date: americanFormattedDate,
      modifiedBy: user.email,
      differences: formattedDifferences,
      cancelled: selectedCancel,
      phone: selectedPhone,
    };
    // console.log("bookingAfterEditServer", bookingAfterEditServer);

    try {
      const response = await customFetch(
        `${config.apiUrl}/api/bookings/edit/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify({
            bookingAfterEditServer: bookingAfterEditServer,
            _id: _id,
            chosenGuideName: chosenGuide.name,
          }),
        }
      );

      if (response.ok) {
        refreshBookings(); // Call the function passed from the parent component
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };

  const handleTourChange = (e) => {
    setSelectedTour(e.target.value);
  };

  const handleSourceChange = (e) => {
    setSelectedSource(e.target.value);
  };
  const phoneInputRef = useRef(null);

  const handlePhoneChange = (e) => {
    const cursorPosition = e.target.selectionStart;
    setTempPhone(e.target.value);
    setTimeout(() => {
      if (phoneInputRef.current) {
        phoneInputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  };

  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, [tempPhone]);

  const handlePhoneBlur = () => {
    setSelectedPhone(tempPhone); // Update selectedPhone only when the input loses focus
  };
  const [validEdits, setValidEdits] = useState(false);

  const [differences, setDifferences] = useState({});
  const formattedDifferences = formatDifferences(differences);
  useEffect(() => {
    const { hasDifferences, differences } = checkAndSetValidEdits(
      bookingBeforeEdit,
      bookingAfterEdit
    );
    setDifferences(differences);
    setValidEdits(hasDifferences && isNumberInputValid);
  }, [
    selectedTour,
    selectedSource,
    selectedTime,
    selectedDate,
    selectedCancel,
    customerState,
    isNumberInputValid,
    selectedPhone,
  ]);

  // TEMPORARY solution to the timepicker losing focus on every input
  const [tempTime, setTempTime] = useState(selectedTime);

  const [cursorPosition, setCursorPosition] = useState(null);

  useEffect(() => {
    if (timePickerRef.current && cursorPosition !== null) {
      timePickerRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [tempTime]);

  const handleTimeChange = (e) => {
    setCursorPosition(e.target.selectionStart);
    setTempTime(e.target.value);
  };
  const timePickerRef = useRef(null);
  useEffect(() => {
    timePickerRef.current.focus();
  }, [tempTime]);

  const handleBlur = () => {
    setSelectedTime(tempTime);
  };

  function formatPhoneNumber(phone) {
    // Remove non-digit characters
    let number = phone.replace(/\D/g, "");

    // Remove leading zeros
    number = number.replace(/^0+/, "");

    return number;
  }

  return (
    <form onSubmit={handleCheckin} id={`dropdown-${_id}`}>
      <ul>
        <section>{customerTypes}</section>
      </ul>
      {submissionError && (
        <InlineNotification
          className="notification-margin"
          kind="error"
          title="Select a value for each customer type to check in"
        />
      )}
      <div className="dropdown-buttons-group">
        <Button type="submit" className="flex-1">
          Check-in
        </Button>
        <ModalStateManager
          renderLauncher={({ setOpen }) => (
            <Button
              kind="secondary"
              ref={editButton}
              onClick={() => setOpen(true)}
              className="flex-1"
            >
              Edit
            </Button>
          )}
        >
          {({ open, setOpen }) => (
            <Modal
              launcherbuttonref={editButton}
              modalHeading="Edit Booking"
              modalLabel=""
              primaryButtonText="Save Changes"
              secondaryButtonText="Cancel"
              open={open}
              onRequestClose={() => setOpen(false)}
              onRequestSubmit={() => {
                handleSaveChanges();
                setOpen(false);
                // refreshBookings(); // Call the function passed from the parent component
              }}
              primaryButtonDisabled={!validEdits} // Disable the button when the form is invalid
            >
              <Column className="edit-form">
                {Object.keys(customerState).map((key, _id) => (
                  <NumberInput
                    key={_id} // Add this line
                    id={`number-input-${key}-${_id}`}
                    label={`${key} Number`}
                    min={0}
                    max={15}
                    value={customerState[key]}
                    onChange={(e, { value }) => {
                      // Calculate new value based on direction

                      setCustomerState((prevState) => ({
                        ...prevState,
                        [key]: Number(value),
                      }));

                      if (value < 0 || value > 15) {
                        setIsNumberInputValid(false);
                      } else {
                        setIsNumberInputValid(true);
                      }
                    }}
                  />
                ))}
                <TourSelect
                  id="edit-tour"
                  tours={tours}
                  value={selectedTour}
                  onChange={handleTourChange}
                />
                {bookingId === "Manually added" && (
                  <SourceSelect
                    id="edit-source"
                    sources={sources}
                    value={selectedSource}
                    onChange={handleSourceChange}
                  />
                )}
                <Row className="edit-time-and-date-row">
                  <TimePicker
                    ref={timePickerRef}
                    id="edit-time"
                    labelText="Tour time"
                    value={tempTime}
                    onChange={handleTimeChange}
                    onBlur={handleBlur}
                  />
                  <DatePicker
                    id="edit-date"
                    dateFormat="d/m/Y"
                    datePickerType="single"
                    value={selectedDate}
                    onChange={(date) => {
                      const dateStr = date[0];
                      if (dateStr) {
                        // Create a new Date object from the string
                        const dateObj = new Date(dateStr);

                        if (!isNaN(dateObj)) {
                          // Extract the day, month, and year from the Date object
                          const day = dateObj.getDate();
                          const month = dateObj.getMonth() + 1; // Months are 0-based in JS
                          const year = dateObj.getFullYear();

                          // Create a date string in the format "YYYY-MM-DD"
                          const formattedDate = `${year}-${month
                            .toString()
                            .padStart(2, "0")}-${day
                            .toString()
                            .padStart(2, "0")}T00:00:00.000Z`;
                          setSelectedDate(formattedDate);
                        } else {
                          console.error("Invalid date string:", dateStr);
                        }
                      }
                    }}
                  >
                    <DatePickerInput
                      id="edit-date-input-id"
                      labelText="Tour Date"
                      placeholder={selectedDate}
                    />
                  </DatePicker>
                </Row>
                <TextInput
                  id="edit-phone"
                  invalidText="A valid value is required"
                  labelText="Phone Number"
                  onChange={handlePhoneChange}
                  value={tempPhone}
                  onBlur={handlePhoneBlur}
                  ref={phoneInputRef}
                />
                <Select
                  id="edit-cancel-booking"
                  value={selectedCancel.toString()}
                  labelText="Cancel Booking"
                  onChange={(e) => setSelectedCancel(e.target.value === "true")}
                  helperText="Select 'Yes' to cancel. This action can NOT be undone."
                >
                  <SelectItem value="true" text="Yes" />
                  <SelectItem value="false" text="No" />
                </Select>
              </Column>
            </Modal>
          )}
        </ModalStateManager>
      </div>
      {(user.role === "Manager"
        ? user.company.showPhoneToManagers
        : user.company.showPhoneToGuides) && (
        <div className="booking-phone-group">
          <Phone />
          {phone ? (
            <a
              className="booking-phone-element"
              href={`https://wa.me/${formatPhoneNumber(phone)}`}
            >
              {phone}
            </a>
          ) : (
            <p>No phone collected</p>
          )}
        </div>
      )}
    </form>
  );
};

export { BookingDropdown };
