import React, { useState } from "react";
const AttendanceButton = ({ item, update, customerType }) => {
  const [customerTypeAttendance, setcustomerTypeAttendance] = useState(0);

  const onAttendanceChange = (e) => {
    setcustomerTypeAttendance(e.target.value);
    update(parseInt(e.target.value), item.index, customerType);
  };

  const customerTypeCapitalized =
    customerType.charAt(0).toUpperCase() + customerType.slice(1);
  const customerPax = Number(Object.values(item));

  return (
    <div className="group-attendance-buttons">
      <p className="label">{`${customerTypeCapitalized} ${customerTypeAttendance}/${Object.values(
        item
      )}`}</p>
      <div className="buttons">
        {Array.from({ length: customerPax + 1 }, (_, i) => i).map((index) => (
          <div key={index}>
            <input
              className="attendance-radio-button"
              label={index}
              type="radio"
              name={`${item.index}${customerType}${customerPax}`}
              value={index}
              onChange={onAttendanceChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AttendanceButton;
